<template>
  <div class="add-user">
    <el-form
      :model="addForm"
      ref="addForm"
      class="add-form"
      label-width="90px"
      size="small"
      :rules="rules"
    >
      <!-- <title-bar title="用户信息">
        <div class="tips">注：新用户初始密码为123456</div>
      </title-bar> -->
      <div class="align-two top-distance">
        <el-form-item label="用户名" prop="userName">
          <el-input
            v-model="addForm.userName"
            style="width: 350px"
            placeholder="请输入"
            maxlength="32"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="真实姓名" prop="realName">
          <el-input
            v-model="addForm.realName"
            style="width: 350px"
            placeholder="请输入"
            maxlength="50"
            clearable
          ></el-input>
        </el-form-item>
      </div>
      <div class="align-two">
        <el-form-item label="性别" prop="sex">
          <el-select
            v-model="addForm.sex"
            placeholder="请选择"
            style="width: 350px"
          >
            <el-option
              v-for="(item, index) in sexList"
              :key="index"
              :value="item.value"
              :label="item.sex"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号" prop="userPhone">
          <el-input
            v-model="addForm.userPhone"
            style="width: 350px"
            placeholder="请输入"
            clearable
            maxlength="11"
          ></el-input>
        </el-form-item>
      </div>
      <div class="align-twos">
        <el-form-item label="角色" prop="roleIds">
          <el-select
            multiple
            v-model="addForm.roleIds"
            placeholder="请选择"
            style="width: 350px"
            value-key="roleId"
          >
            <el-option
              v-for="(role, index) in rolesList"
              :key="index"
              :label="role.roleName"
              :value="role.roleId"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="是否可登陆处置平台" class="isTrue">
          <el-radio v-model="isLoginDisposal" label="1">是</el-radio>
          <el-radio v-model="isLoginDisposal" label="2">否</el-radio>
        </el-form-item> -->
      </div>
      <!-- <el-form-item label="部门名称">
        <el-tree
          :data="departmentList"
          show-checkbox
          node-key="departmentId"
          ref="tree"
          highlight-current
          :default-expand-all="true"
          :props="defaultProps"
          @check="handleNodeClick"
          current-node-key="departmentId"
          :filter-node-method="filterNode"
          >
        </el-tree>
      </el-form-item> -->
    </el-form>
    <!-- <div class="form-btn">
      <el-button size="mini" @click="goBack">取消</el-button>
      <el-button size="mini" class="ml30" type="primary" @click="addUserInfo(1)" v-if="!userId">提 交</el-button>
      <el-button size="mini" class="ml30" type="primary" @click="editUserInfo(1)" v-if="userId">提 交</el-button>
    </div> -->
    <!-- 重复名弹框 -->
    <!-- <el-dialog title="提示" :visible.sync="dialogVisible" width="20%">
      <div class="dialog-tips">真实姓名已存在，是否继续？</div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button
          size="small"
          type="primary"
          @click="addUserInfo(2)"
          v-if="dialogTips == '1'"
          >确 定</el-button
        >
        <el-button size="small" type="primary" @click="editUserInfo(2)" v-else
          >确 定</el-button
        >
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
// import titleBar from "@/components/titleBar";
import { v4 as uuidv4 } from "uuid";
export default {
  // components: {
  //   titleBar,
  // },
  props: {
    //编辑类型 add 新增，edit 修改
    editType: {
      type: String,
      default: "add",
    },
    //当前编辑的用户ID
    editUserId: String,
  },
  watch: {
    editType: function (val) {
      // this.userId=this.editUserId;
      this.localEditType = this.editType;
    },
    editUserId:function(val){
       this.userId=this.editUserId;
    }
  },
  data() {
    return {
      // dialogVisible: false,
      isLoginDisposal: "2",
      addForm: {
        userName: "", //用户名
        realName: "", //真实姓名
        sex: "", //性别
        userPhone: "", //手机号
        // departmentIds: "", //部门
        roleIds: [], //角色
      },
      // 部门
      departments: [],
      listQuery: {
        // total: 1000,
        // current:1000,
        size: 1000,
      },
      // userId:"",//
      // departmentData:[],//部门
      sexList: [
        { value: 1, sex: "男" },
        { value: 0, sex: "女" },
      ],
      departmentList: [],
      rolesList: [],
      rules: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        realName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        userPhone: [
          { required: true, message: "请输入正确的手机号", trigger: "blur" },
          { message: "请输入11位手机号", min: 11, max: 11 },
        ],
        departmentIds: [
          { required: true, message: "请选择部门", trigger: "blur" },
        ],
        roleIds: [{ required: true, message: "请选择角色", trigger: "blur" }],
      },
      defaultProps: {
        children: "children",
        label: "departmentName",
      },
      departlist: [],
      database: [],
      departArr: [],
      data: [],
      departmentIds: [],
      roleIdArr: [],
      roleArr: [],
      dialogTips: "",
      userId: this.editUserId, //当前编辑的用户ID
      localEditType:this.editType,//边界类型，add 新增，edit修改
    };
  },
  mounted() {
    //this.isAdd = this.$route.query.isAdd;
    // this.userId = this.$route.query.userId;
    // this.getAllPartment();
    // this.getDepartmentData();
    this.rolePageData();
      //修改
      if (this.localEditType == "edit") {
        this.userEcho();
        //新增
      } else if (this.localEditType == "add") {
        this.userId = uuidv4().replace(/\-/g, "");
      } else {
        this.$message.error("localEditType错误");
      }
  },
  methods: {
    // reset() {
    //   this.addForm = {};
    //   //修改
    //   if (this.localEditType == "edit") {
    //     this.userEcho();
    //     //新增
    //   } else if (this.localEditType == "add") {
    //     this.userId = uuidv4().replace(/\-/g, "");
    //   } else {
    //     this.$message.error("localEditType错误");
    //   }
    // },
    confirmEdit() {
      //修改
      if (this.localEditType == "edit") {
        this.editUserInfo();
        //新增
      } else if (this.localEditType == "add") {
        this.addUserInfo();
      } else {
        this.$message.error("localEditType错误");
      }
    },
    // getAllPartment() {
    //   getSysDepartmentList().then(res=>{
    //     if(res.code==1) {
    //       this.departmentList = res.data;
    //       console.log(res.data,"glkh");
    //       this.getDepartmentData()
    //     }
    //   })
    // },
    // 获取部门
    // getDepartmentData() {
    //   getLoginUserDepartmentParkAll({userId:this.userId}).then(res=>{
    //     if(res.code===1) {
    //       this.departArr = res.data;
    //       console.log(res.data,"lllll")
    //       let that = this;
    //       this.$nextTick(()=>{
    //         setTimeout(()=>{
    //           res.data.forEach(value=>{
    //             that.$refs.tree.setChecked(value,true,false)
    //           })
    //         })
    //       })
    //     }
    //   })

    // },
    // 获取角色
    rolePageData() {
    },
    // 回显
    userEcho() {
      
    },
    // 勾选
    handleNodeClick(data, checkedKeys) {
      this.departmentIds = checkedKeys.checkedKeys.concat(
        checkedKeys.halfCheckedKeys
      );
      // console.log(this.departmentIds.length,"fffffffffff")
    },
    filterNode() {},
    // 提交
    addUserInfo() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          let data = {
            ...this.addForm,
            realName: this.addForm.realName,
            // departments:this.departmentList,
            isLoginDisposal: Number(this.isLoginDisposal),
            departmentIds: this.departmentIds,
            userId: this.userId,
          };

           data.roleIds=this.addForm.roleIds.join(",")
          // console.log(data,"data")
         
                this.$message({
                  type: "success",
                  message: "提交新增",
                });
              
        }
      });
    },
    // 编辑
    editUserInfo() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          let arr = [];
          let roleData = [];
          if (this.departmentIds.length === 0) {
            this.departArr.forEach((item) => {
              arr.push(item.departmentId);
            });
          } else {
            arr = this.departmentIds;
          }
          // 角色问题
          // this.rolesList.forEach(item=>{
          //   this.roleIdArr.forEach(val=>{
          //     if(item.roleId === val) {
          //       roleData.push(item.roleId)
          //     }
          //   })
          // })
          // this.addForm.roles = roleData;
          // console.log(this.addForm.roles,"rolesddff");

          let data = {
            // ...this.addForm,
            userName: this.addForm.userName,
            realName: this.addForm.realName,
            realName: this.addForm.realName,
            sex: this.addForm.sex,
            userPhone: this.addForm.userPhone,
            roleIds: this.addForm.roleIds.join(","),
            departmentIds: arr,
            isLoginDisposal: Number(this.isLoginDisposal),
            userId: this.userId,
          };
        
          // console.log(data,"data")
                this.$message({
                  type: "success",
                  message: "编辑",
                });
        }
      });
    },
   // goBack() {
      // this.$router.push({
      //   path: "/main/authorityManagement/userManagement",
      // });
  //  },
  },
};
</script>

<style lang="less" scoped>
.add-user {
  width: 1000px;
  margin: 0 auto;
  overflow: hidden;
  .tips {
    font-size: 14px;
    font-weight: 400;
    color: #ff4e4e;
  }
  .align-two {
    display: flex;
    justify-content: space-between;
  }
  .top-distance {
    margin-top: 26px;
  }
  .form-btn {
    text-align: center;
    margin-top: 30px;
    /deep/.el-button--mini,
    .el-button--mini.is-round {
      padding: 9px 32px;

      font-size: 14px;
    }
  }
  .ml30 {
    margin-left: 30px;
  }
  .align-twos {
    display: flex;
  }
  .isTrue {
    margin-left: 130px;
  }
  .dialog-tips {
    font-size: 16px;
    text-align: center;
  }
}
</style>